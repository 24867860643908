<template>
    <div>
        <!-- CAROUSEL-->
    <!-- <b-row>
      <b-col cols="12" >
        <div>
          <b-carousel indicators :interval="3000" controls >
            <b-carousel-slide       
                v-if="resources!=null"         
                :img-src="resources.img_header_principal"
            />
          </b-carousel>
        </div>
      </b-col>
    </b-row>
     -->
        <br>

        <!-- <h1 class="titles">
          <span style="color: #d82125">M</span>
          <span style="color: #d02175">u</span>
          <span style="color: #f6c921">n</span>
          <span style="color: #d02175">i</span>
          <span style="color: #4d944a">c</span>
          <span style="color: #07aaba">i</span>
          <span style="color: #d82125">p</span>
          <span style="color: #d02175">i</span>
          <span style="color: #f6c921">o</span>
        </h1> -->
        
        <h1 class="titles">
          <span style="color: #8b8c8c">Actividades Turísticas</span>
        </h1>



        <br><br>

<b-row >

        <b-col>
            <b-tabs content-class="mt-3" justified>

                <b-tab active>
                     <template #title>
                        <strong>Tours</strong>
                    </template>
                    <br>
                    <h2 class="titles">
                                <span style="color: #8b8c8c">Tours</span>
                    </h2>
                    <br>
                    <b-row align-h="center">
                      <b-col cols="11" md="9" lg="5" xl="4" >
                        <b-carousel :interval="3000" controls indicators >
                          <b-carousel-slide  img-src="https://www.teotihuacan.gob.mx/turismot/3%20atracciones/galeria/tour.jpg"></b-carousel-slide>
                          <b-carousel-slide  img-src="https://www.teotihuacan.gob.mx/turismot/3%20atracciones/galeria/2.jpeg"></b-carousel-slide>
                          <b-carousel-slide  img-src="https://www.teotihuacan.gob.mx/turismot/3%20atracciones/galeria/tour3.jpg"></b-carousel-slide>
                        </b-carousel>
                      </b-col>
                    </b-row>

                    <b-row align-h="center">
                      <b-col cols="11" md="9" lg="5" xl="4" class="mt-4">
                        <p>
                          <b>
                            A bordo del Tranvía disfrutarás de un recorrido alrededor de la Zona Arqueológica de Teotihuacán, conocerás su historia y disfrutaras de la mejor vista de los lugares emblemáticos de la Zona, como las pirámides del Sol y la Luna, Tepantitla, la calzada de los muertos, Tetitla y Atetelco. Además de escuchar las leyendas que guardan estos lugares desde tiempos prehispánicos.
                          </b>
                        </p>
                        <br><br>
                      </b-col>
                    </b-row>

                    <b-row  align-h="center"  v-for="(item, idx) in datos " :key="idx" >
                            <b-col cols="12" class="mt-4" v-if="item.tipo=='T'" ></b-col>
                            <!-- <b-col cols="12" sm="10" md="5" lg="3"  class="mb-3" v-if="item.tipo=='T'" >
                                <b-container  style="cursor:pointer;">
                                <b-img :src="'https://www.teotihuacan.gob.mx/turismot/3%20atracciones/'+item.img+'.jpg'" fluid rounded class="c-shadow-sm" ></b-img>
                                <b-img :src="'https://www.teotihuacan.gob.mx/turismot/3%20atracciones/'+item.img+'.jpeg'" fluid rounded class="c-shadow-sm" ></b-img>
                                </b-container>
                            </b-col> -->
                            <b-col cols="10" sm="8" md="5" lg="4" v-if="item.tipo=='T'">
                                <b-row><h5><b>{{item.nombre}}</b></h5></b-row>
                                <b-row><h6><b-icon icon="geo-fill"/> {{item.direccion}}</h6></b-row>
                                <b-row><h6><b-icon icon="telephone-fill"/> {{item.telefono}}</h6></b-row>
                                <b-row v-if="item.url!=null && item.url!='' && !item.url.includes('@')"><h6><b-icon icon="link"/><a :href="item.url" target="_blank"> Visitar Sitio</a></h6></b-row>
                            </b-col>
                            <b-col cols="12" class="mb-5" v-if="item.tipo=='T'" ></b-col>
                        
                    </b-row>
                </b-tab>


                <b-tab >
                     <template #title>
                        <strong>Motos</strong>
                    </template>
                    <br>
                    <h2 class="titles">
                                <span style="color: #8b8c8c">Motos</span>
                    </h2>
                    <br>
                    <b-row align-h="center">
                      <b-col cols="11" md="9" lg="5" xl="4" >
                        <b-carousel :interval="3000" controls indicators >
                          <b-carousel-slide img-src="https://www.teotihuacan.gob.mx/turismot/3%20atracciones/galeria/motos.jpg"></b-carousel-slide>
                          <!-- <b-carousel-slide img-src="https://www.teotihuacan.gob.mx/turismot/3%20atracciones/galeria/cuatrix.jpeg"></b-carousel-slide> -->
                        </b-carousel>
                      </b-col>
                    </b-row>

                    <b-row align-h="center">
                      <b-col cols="11" md="9" lg="5" xl="4" class="mt-4">
                        <p>
                          <b>
                            Tour en cuatrimotos guiadas por profesionales donde se visitan zonas aledañas a la Zona Arqueológica, dando a conocer diversos atractivos como cuevas y templos. 
                          </b>
                        </p>
                      </b-col>
                    </b-row>

                    <b-row  align-h="center"  v-for="(item, idx) in datos " :key="idx" >
                            <b-col cols="12" class="mt-4" v-if="item.tipo=='CT'" ></b-col>
                            <!-- <b-col cols="12" sm="10" md="5" lg="3"  class="mb-3" v-if="item.tipo=='T'" >
                                <b-container  style="cursor:pointer;">
                                <b-img :src="'https://www.teotihuacan.gob.mx/turismot/3%20atracciones/'+item.img+'.jpg'" fluid rounded class="c-shadow-sm" ></b-img>
                                <b-img :src="'https://www.teotihuacan.gob.mx/turismot/3%20atracciones/'+item.img+'.jpeg'" fluid rounded class="c-shadow-sm" ></b-img>
                                </b-container>
                            </b-col> -->
                            <b-col cols="10" sm="8" md="5" lg="4" v-if="item.tipo=='CT'">
                                <b-row><h5><b>{{item.nombre}}</b></h5></b-row>
                                <b-row><h6><b-icon icon="geo-fill"/> {{item.direccion}}</h6></b-row>
                                <b-row><h6><b-icon icon="telephone-fill"/> {{item.telefono}}</h6></b-row>
                                <b-row v-if="item.url!=null && item.url!='' && !item.url.includes('@')"><h6><b-icon icon="link"/><a :href="item.url" target="_blank"> Visitar Sitio</a></h6></b-row>
                            </b-col>
                            <b-col cols="12" class="mb-5" v-if="item.tipo=='CT'" ></b-col>
                        
                    </b-row>


                </b-tab>

                <b-tab >
                     <template #title>
                        <strong>Bicicleta</strong>
                    </template>
                    <br>
                    <h2 class="titles">
                                <span style="color: #8b8c8c">Experiencia en bicicletas</span>
                    </h2>
                    <br>
                    <b-row align-h="center">
                      <b-col cols="11" md="9" lg="5" xl="4" >
                        <b-carousel :interval="3000" controls indicators >
                          <b-carousel-slide img-src="https://www.teotihuacan.gob.mx/turismot/3%20atracciones/galeria/bici1.jpg"></b-carousel-slide>
                          <b-carousel-slide img-src="https://www.teotihuacan.gob.mx/turismot/3%20atracciones/galeria/bici2.jpg"></b-carousel-slide>
                        </b-carousel>
                      </b-col>
                    </b-row>

                    <b-row align-h="center">
                      <b-col cols="11" md="9" lg="5" xl="4" class="mt-4">
                        <p>
                          <b>
                            Recorridos ciclistas guiados por la zona arqueológica, donde se visitan y explican diversos lugares sobresalientes de dicha cultura.
                          </b>
                        </p>
                      </b-col>
                    </b-row>

                    <b-row  align-h="center"  v-for="(item, idx) in datos " :key="idx" >
                            <b-col cols="12" class="mt-4" v-if="item.tipo=='BC'" ></b-col>
                            <b-col cols="10" sm="8" md="5" lg="4" v-if="item.tipo=='BC'">
                                <b-row><h5><b>{{item.nombre}}</b></h5></b-row>
                                <b-row><h6><b-icon icon="geo-fill"/> {{item.direccion}}</h6></b-row>
                                <b-row><h6><b-icon icon="telephone-fill"/> {{item.telefono}}</h6></b-row>
                                <b-row v-if="item.url!=null && item.url!='' && !item.url.includes('@')"><h6><b-icon icon="link"/><a :href="item.url" target="_blank"> Visitar Sitio</a></h6></b-row>
                            </b-col>
                            <b-col cols="12" class="mb-5" v-if="item.tipo=='BC'" ></b-col>
                        
                    </b-row>
                </b-tab>


                <b-tab >
                     <template #title>
                        <strong>Cuevas</strong>
                    </template>
                    <br>
                    <h2 class="titles">
                                <span style="color: #8b8c8c">Experiencia en Cuevas</span>
                    </h2>
                    <br>
                    <b-row align-h="center">
                      <b-col cols="11" md="9" lg="5" xl="4" >
                        <b-carousel :interval="3000" controls indicators >
                          <!-- <b-carousel-slide img-src="https://www.teotihuacan.gob.mx/turismot/3%20atracciones/galeria/cuevas2.jpg"></b-carousel-slide> -->
                          <b-carousel-slide img-src="https://www.teotihuacan.gob.mx/turismot/3%20atracciones/galeria/cuevas.jpg"></b-carousel-slide>
                        </b-carousel>
                      </b-col>
                    </b-row>

                    <b-row align-h="center">
                      <b-col cols="11" md="9" lg="5"  xl="4" class="mt-4">
                        <p>
                          <b>
                            Conoceras la antigüa reserva natural prehispanica, posee grandes secretos y misterios de esta antigua civilización , adentrandose en cavernas naturales y conociendo los lugares a donde llevan estas cavernas.
                          </b>
                        </p>
                      </b-col>
                    </b-row>
                </b-tab>

                <b-tab >
                     <template #title>
                        <strong>Vuelo en Globo</strong>
                    </template>
                    <br>
                    <h2 class="titles">
                                <span style="color: #8b8c8c">Vuela en Globo Aerostático</span>
                    </h2>
                    <br>
                    <b-row align-h="center">
                      <b-col cols="11" md="9" lg="5" xl="4" >
                        <b-carousel :interval="3000" controls indicators >
                          <b-carousel-slide img-src="https://www.teotihuacan.gob.mx/turismot/3%20atracciones/galeria/globo1.jpg"></b-carousel-slide>
                          <b-carousel-slide img-src="https://www.teotihuacan.gob.mx/turismot/3%20atracciones/galeria/globo2.jpg"></b-carousel-slide>
                          <b-carousel-slide img-src="https://www.teotihuacan.gob.mx/turismot/3%20atracciones/galeria/globo3.jpg"></b-carousel-slide>
                          <b-carousel-slide img-src="https://www.teotihuacan.gob.mx/turismot/3%20atracciones/galeria/globo4.jpg"></b-carousel-slide>
                        </b-carousel>
                      </b-col>
                    </b-row>

                    <b-row align-h="center">
                      <b-col cols="11" md="9" lg="5"  xl="4" class="mt-4">
                        <p>
                          <b>
                            Vuelo sobre el municipio de Teotihuacán disfrutando la increible vista aerea y el amanecer. Ya sea celebrando algun momento especial o pasando un buen momento en compañía de tus seres queridos.
                          </b>
                        </p>
                      </b-col>
                    </b-row>

                    <br><br>
                    <b-row  align-h="center"  v-for="(item, idx) in datos " :key="idx" >
                            <b-col cols="12" class="mt-4" v-if="item.tipo=='G'" ></b-col>
                            <b-col cols="10" sm="8" md="5" lg="4" v-if="item.tipo=='G'">
                                <b-row><h5><b>{{item.nombre}}</b></h5></b-row>
                                <b-row><h6><b-icon icon="geo-fill"/> {{item.direccion}}</h6></b-row>
                                <b-row><h6><b-icon icon="telephone-fill"/> {{item.telefono}}</h6></b-row>
                                <b-row v-if="item.url!=null && item.url!='' && !item.url.includes('@')"><h6><b-icon icon="link"/><a :href="item.url" target="_blank"> Visitar Sitio</a></h6></b-row>
                            </b-col>
                            <b-col cols="12" class="mb-5" v-if="item.tipo=='G'" ></b-col>
                        
                    </b-row>

                    <br>
                    <h2 class="titles">
                                <span style="color: #8b8c8c">Elevaciones</span>
                    </h2>
                    <br>
                    <b-row  align-h="center"  v-for="(item, idx) in datos " :key="idx" >
                            <b-col cols="12" class="mt-4" v-if="item.tipo=='E'" ></b-col>
                            <b-col cols="10" sm="8" md="5" lg="4" v-if="item.tipo=='E'">
                                <b-row><h5><b>{{item.nombre}}</b></h5></b-row>
                                <b-row><h6><b-icon icon="geo-fill"/> {{item.direccion}}</h6></b-row>
                                <b-row><h6><b-icon icon="telephone-fill"/> {{item.telefono}}</h6></b-row>
                                <b-row v-if="item.url!=null && item.url!='' && !item.url.includes('@')"><h6><b-icon icon="link"/><a :href="item.url" target="_blank"> Visitar Sitio</a></h6></b-row>
                            </b-col>
                            <b-col cols="12" class="mb-5" v-if="item.tipo=='E'" ></b-col>
                        
                    </b-row>

                </b-tab>

              

                <b-tab >
                     <template #title>
                        <strong>Zoológico</strong>
                    </template>
                    <br>
                    <h2 class="titles">
                                <span style="color: #8b8c8c">Parque Reino Animal</span>
                    </h2>
                    <br>
                    <b-row align-h="center">
                      <b-col cols="11" md="9" lg="5" xl="4" >
                        <b-carousel :interval="3000" controls indicators >
                          <b-carousel-slide img-src="https://www.teotihuacan.gob.mx/turismot/3%20atracciones/galeria/reino1.jpg"></b-carousel-slide>
                          <b-carousel-slide img-src="https://www.teotihuacan.gob.mx/turismot/3%20atracciones/galeria/reino2.jpg"></b-carousel-slide>
                          <b-carousel-slide img-src="https://www.teotihuacan.gob.mx/turismot/3%20atracciones/galeria/reino3.jpg"></b-carousel-slide>
                        </b-carousel>
                      </b-col>
                    </b-row>

                    <b-row align-h="center">
                      <b-col cols="11" md="9" lg="5"  xl="4" class="mt-4">
                        <p>
                          <b>
                            Centro recreativo donde podrás interactuar con mas de 1200 ejemplares de los cuales el 60% son rescatados.
                          </b>
                          <b>
                            Safari, Aviario, Santuario Jaguar, Aguilario, Reptilario y Felinos.
                          </b>
                        </p>
                      </b-col>
                    </b-row>

                    <br><br>

                    <b-row  align-h="center"  v-for="(item, idx) in datos " :key="idx" >
                            <b-col cols="12" class="mt-4" v-if="item.tipo=='RA'" ></b-col>
                            <b-col cols="10" sm="8" md="5" lg="4" v-if="item.tipo=='RA'">
                                <b-row><h5><b>{{item.nombre}}</b></h5></b-row>
                                <b-row><h6><b-icon icon="geo-fill"/> {{item.direccion}}</h6></b-row>
                                <b-row><h6><b-icon icon="telephone-fill"/> {{item.telefono}}</h6></b-row>
                                <b-row v-if="item.url!=null && item.url!='' && !item.url.includes('@')"><h6><b-icon icon="link"/><a :href="item.url" target="_blank"> Visitar Sitio</a></h6></b-row>
                            </b-col>
                            <b-col cols="12" class="mb-5" v-if="item.tipo=='RA'" ></b-col>
                        
                    </b-row>
                </b-tab>

                <b-tab >
                     <template #title>
                        <strong>Balnearios</strong>
                    </template>
                    <br>
                    <h2 class="titles">
                                <span style="color: #8b8c8c">Balnearios</span>
                    </h2>
                    <br>
                    <b-row align-h="center">
                      <b-col cols="11" md="9" lg="5" xl="4" >
                        <b-carousel :interval="3000" controls indicators >
                          <b-carousel-slide img-src="https://www.teotihuacan.gob.mx/turismot/3%20atracciones/galeria/balneario1.jpg"></b-carousel-slide>
                          <b-carousel-slide img-src="https://www.teotihuacan.gob.mx/turismot/3%20atracciones/galeria/balneario2.jpg"></b-carousel-slide>
                        </b-carousel>
                      </b-col>
                    </b-row>

                    <b-row align-h="center">
                      <b-col cols="11" md="9" lg="5"  xl="4" class="mt-4">
                        <p>
                          <b>
                            Cuentan con  albercas al aire libre y con toboganes, chapoteaderos,con agua tibia, zona de asadores y palapas, tienda de autoservicio y estacionamiento. Al igual cuenta con espacios recreativos como lo son canchas para practicar algun deporte al intemperie.
                          </b>
                        </p>
                      </b-col>
                    </b-row>

                    <br><br>
                    <b-row  align-h="center"  v-for="(item, idx) in datos " :key="idx" >
                            <b-col cols="12" class="mt-4" v-if="item.tipo=='BA'" ></b-col>
                            <b-col cols="10" sm="8" md="5" lg="4" v-if="item.tipo=='BA'">
                                <b-row><h5><b>{{item.nombre}}</b></h5></b-row>
                                <b-row><h6><b-icon icon="geo-fill"/> {{item.direccion}}</h6></b-row>
                                <b-row><h6><b-icon icon="telephone-fill"/> {{item.telefono}}</h6></b-row>
                                <b-row v-if="item.url!=null && item.url!='' && !item.url.includes('@')"><h6><b-icon icon="link"/><a :href="item.url" target="_blank"> Visitar Sitio</a></h6></b-row>
                            </b-col>
                            <b-col cols="12" class="mb-5" v-if="item.tipo=='BA'" ></b-col>                        
                    </b-row>
                </b-tab>

            </b-tabs>
        </b-col>
    </b-row>



<br><br>


    </div>
</template>
<script>
  export default {
    components: {
    },
    mounted: function(){
      
    },
    computed: {
      
    },
    data() {
      return {
        resources:null,
        datos:[
         {img:'1',nombre:'Tranvia Quetazlbus',direccion:'Maribel Galicia',telefono:'5532035871',url:'',tipo:'T'},
//{img:'2',nombre:'Tranvia  xixo',direccion:'Magdalena',telefono:'5579824552',url:'',tipo:'T'},
//{img:'3',nombre:'Tranvia  Xantolo',direccion:'Elías Saenz',telefono:'5534465096',url:'',tipo:'T'},
//{img:'4',nombre:'Tranvia  Retrobuses Chipiltepec ',direccion:'Luis David Aguilar Velasco',telefono:'5516467864',url:'',tipo:'T'},
//{img:'',nombre:'NOMBRE ',direccion:'CONTACTO ',telefono:'TELEFONO',url:'',tipo:''},
//{img:'1',nombre:'Autocinema ',direccion:'Juárez No. 8, Purificacion, 55804 San Juan Teotihuacan de Arista, Méx.',telefono:'55 6800 5706',url:'https://es-la.facebook.com/autocinema.retrovisor/',tipo:'O'},
//{img:'2',nombre:'Juego de pelota ',direccion:'',telefono:' 55 1072 7681',url:'',tipo:'O'},
//{img:'3',nombre:' Tours Teotihuacano',direccion:'Calle la palma San franciscvo Mazapa Teotihuacán',telefono:'5535308522',url:'Facebook: Tour teotihuacano',tipo:'T'},
{img:'4',nombre:'Parque Reino Animal ',direccion:'Camino a Belen Santa María S/N, 55908 Oxtotipac, Méx.',telefono:'55 6111 5071',url:'https://www.reinoanimal.com.mx/',tipo:'RA'},
//{img:'5',nombre:'Aventura en bici ',direccion:'Calle Emiliano Zapata 2B, San Francisco Mazapa, Teotihuacán',telefono:'(52) 55 4980 6931- Ingles   (52) 5550095067- Español ',url:'https://www.teotihuacanenbici.com.mx/tours-teotihuacan',tipo:'B'},
//{img:'',nombre:'Balneario Gabi ',direccion:'Av. Pirámides 30, Teotihuacan Centro, 55810 San Juan Teotihuacan de Arista, Méx.',telefono:'',url:'https://es-la.facebook.com/pages/category/Interest/Balneario-GABY-193685494174953/',tipo:'BA'},
//{img:'',nombre:' Balneario la Boing ',direccion:'Curva la Garita s/n, Atlatongo, 55823 San Juan Teotihuacan de Arista, Méx.',telefono:'',url:'https://www.pascual.com.mx/centro-recreativo/',tipo:'BA'},
//{img:'1',nombre:'Volare ',direccion:'carretera libre México Tulancingo km. 27.5, san francisco mazapa 55830',telefono:'5510881275          5553312460',url:'https://volare.com.mx/nuestros-vuelos/?gclid=Cj0KCQiAjJOQBhCkARIsAEKMtO11ENX_jMT72eqFZ_fJOxTU3I3EvrXGQ-pD0WX_IJJxGa3KFE4HLXQaAlkjEALw_wcB',tipo:'G'},
//{img:'2',nombre:'Aventura en globo',direccion:'av. De la amistad s/n san francisco mazapa 55800 san juan teotihuacan',telefono:'5535725243',url:'https://aventuraenglobos.com/',tipo:'G'},
//{img:'',nombre:'Aero diverti',direccion:'carretera federal tulancingo km. 28.5 san amrtin de las piramides',telefono:'5569433112',url:'https://aerodiverti.com.mx/?gclid=Cj0KCQiAjJOQBhCkARIsAEKMtO1STYbX7Fqm2elM2IdEaLuC2v9LvHMiyvOdEYiT-yDseoZoCagtkuAaAl08EALw_wcB',tipo:'G'},
//{img:'',nombre:'Sky ballons',direccion:'carretera mexico tulancingo km. 28 int. 1 francisco villa 55830 san martin centro',telefono:'5558703544       5580972716        5574011865',url:'https://www.skyballoons.mx/?gclid=Cj0KCQiAjJOQBhCkARIsAEKMtO2qSeourteIBSTUhK2uSIU2JiCXCtmnQI91Gy2Q219YfCn_PBtr-iEaAmwdEALw_wcB',tipo:'G'},
//{img:'',nombre:'Volar en globo',direccion:' francisco villa s/n carretera mexico tulancingo km. 28 CP. 55850 San Martin Centro',telefono:'',url:'https://www.volarenglobo.com.mx/',tipo:'G'},
//{img:'',nombre:'Globo tetris',direccion:'carretera federal tulancingo francisco villa xometla corral CP. 55850 km. 28.5 san martin de las piramides',telefono:'5519233073',url:'https://www.facebook.com/globo.tetris/',tipo:'G'},
//{img:'',nombre:'Flying pictures de mexico, vuelos en globo aerostatico teotihuacan',direccion:'carretera mexico tulancingo km. 28 CP.55830 san martin centro',telefono:'5553983167',url:'https://flyingpictures.mx/',tipo:'E'},
//{img:'',nombre:'Globo aerostatico teotihuacan',direccion:'AV. Miguel Hidalgo No. 56 cantro san juan teotihuacan',telefono:'5514540469         5538963770',url:'https://globosaerostaticosteotihuacan.com/',tipo:'G'},
//{img:'',nombre:'Globopuerto teotihuacan',direccion:'',telefono:'5533220766       5627073671',url:'https://www.globopuertoteotihuacan.com/vuelos',tipo:'G'},
//{img:'',nombre:'Vuelos en globo mx',direccion:'Carretera libre México Tulancingo km. 27.5, san francisco mazapa 55830, san Martin centro Mexico',telefono:'5550151213',url:'https://vuelosenglobo.mx/',tipo:'G'},
//{img:'',nombre:'Globos aerostaticos ',direccion:'carretera tulancingo km.28.2 san martin de las piramides 55800',telefono:'5526206749      5627072797      5630213410',url:'http://globosaerostaticosmexico.com/',tipo:'G'},
//{img:'',nombre:'Nuevo cielo globo aerostatico',direccion:'carretera tulancingo km.28.4 san martin de las piramides 55830',telefono:'5548114321',url:'https://nuevocielo.com.mx/',tipo:'E'},
//{img:'',nombre:'Elevaciones en globo',direccion:'',telefono:'',url:'',tipo:''},
//{img:'',nombre:'Fiesta magica o festival magico de globos',direccion:'calle barranca s/n purificacion 55890 San Juan Teotihuacán',telefono:'55 7657 4371',url:'https://fiestamagica.mx/',tipo:'E'},
//{img:'',nombre:'Noches Ancestrales ',direccion:'C. Benito Juarez Santa Maria coatlan Teotihuacán ',telefono:'5510727681',url:'https://www.facebook.com/NochesAncestralesTeoti/',tipo:'E'},
//{img:'',nombre:'Clazada de lo muertos',direccion:'',telefono:'',url:'',tipo:''},
//{img:'16',nombre:'Elevaciones Harej San Francisco Mazapa ',direccion:'Pl. Francisco I. Madero, 55820 San Francisco Mazapa, Méx.',telefono:'55 1291 2685',url:'https://www.facebook.com/elevacionengloboteotihuacan/',tipo:'E'},



//{img:'',nombre:'Aventura en bici ',direccion:'Calle Emiliano Zapata 2B, San Francisco Mazapa, Teotihuacán',telefono:'(52) 55 4980 6931 - Ingles   (52) 5550095067- Español ',url:'https://www.teotihuacanenbici.com.mx/tours-teotihuacan',tipo:'BC'},
//{img:'',nombre:'Cuatrix Teotihuacán',direccion:'Jardín de las Cactáceas Teotihuacán',telefono:'5621780798, 5614562069',url:'https://instagram.com/cuatrix_teotihuacan?utm_medium=copy_link',tipo:'CT'},
//{img:'',nombre:'Tours Teotihuacano',direccion:'Calle la Palma San Francisco Mazapa Teotihuacán',telefono:'5621780798, 5614562069',url:'https://instagram.com/cuatrix_teotihuacan?utm_medium=copy_link',tipo:'CT'},

//{img:'',nombre:'Autocinema',direccion:'Juárez No. 8, Purificacion, 55804 San Juan Teotihuacan de Arista, Méx.',telefono:' 55 6800 5706',url:'https://es-la.facebook.com/autocinema.retrovisor/',tipo:'T'},
//{img:'',nombre:'Juego de pelota ',direccion:'Martha Fabiola Martínez Martínez',telefono:' 55 1072 7681',url:'',tipo:'T'},

        ],
        
      }
    }
    
  }
</script>